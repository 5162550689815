@import 'config.scss';

.SearchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(90, 90, 90, 0.3);
  z-index: 9998;

  @include breakpoint($md) {
    padding: 80px 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: {
      top: 20px;
      right: 8px;
      left: 20px;
      bottom: 20px;
    }
    background-color: $selago;
    box-sizing: border-box;
    overflow: hidden;

    @include breakpoint($md) {
      width: 80%;
      max-width: 735px;
      min-height: 250px;
      max-height: 700px;
      border-radius: 10px;
    }
  }

  .inputWrapper {
    display: flex;
  }

  .inputButton {
    border: none;
    background: none;
    margin-left: 16px;
    cursor: pointer;
  }

  .content {
    margin-top: 30px;
    padding: {
      right: 12px;
    }
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 223px;
      background: rgba(146, 160, 196, 0.4);
      border-radius: 5px;
      transition: ease 0.15s background;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(146, 160, 196, 0.8);
    }
  }

  .group {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .title {
    margin-bottom: 15px;
    font-weight: $semi-bold;
  }

  .hit {
    display: flex;
    align-items: center;
    padding: 15px 19px;
    box-sizing: border-box;
    background: $white;
    margin-bottom: 4px;
    border-radius: 10px;
    text-decoration: none;
    transition: ease 0.2s all;
    border: 1px solid #ecf0fa;
    box-shadow: 0px 2px 4px rgba(117, 141, 166, 0.1);

    &:hover {
      background: $royal-blue;

      a,
      p {
        color: $white;
      }
    }
  }

  .resultTextContent {
    flex: 1;
  }

  .resultTitle {
    p {
      color: #3c548a;
      font-size: 13px;

      @include breakpoint($md) {
        font-size: 15px;
      }
    }
  }

  .resultSubtitle {
    p {
      color: #3c548a;
      font-size: 11px;

      @include breakpoint($md) {
        font-size: 11px;
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 4px;

    &.user-docs {
      background: linear-gradient(141.83deg, #fc8c4e -28%, #dd5f37 95.31%);
    }

    &.developer-docs {
      background: linear-gradient(141.83deg, #a06aff -28%, #9646fb 95.31%);
    }

    &.blog_posts {
      background: linear-gradient(144.62deg, #f73457 6.61%, #d5324f 107.06%);
    }

    &.tutorials {
      background: linear-gradient(324.6deg, #e19500 -14.86%, #f0b747 90.5%);
    }

    &.starters {
      background: linear-gradient(331deg, #10a93b 3.74%, #1bc77a 133.95%);
    }
  }
}

.banner {
  top: -$banner-mobile-height;

  @include breakpoint($md) {
    top: -$banner-desktop-height;
  }
}

.noResults {
  margin-top: 30px;
  text-align: center;

  .noResultsIcon {
    margin-bottom: 18px;
  }

  .searchedTerm {
    color: $purple-500;
  }
}
