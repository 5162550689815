@import 'config.scss';
@import 'marketplace/styles/Header/index.scss';

.navigationContainer {
  position: sticky;
  top: 0;
  // This transform fixes gap between header and page
  transform: translateY(-1px);
  z-index: 9998;
}

.largeScreenNav,
.smallScreenNav {
  position: relative;
  height: 80px;

  &.hasStickyBar {
    height: 80px;
    position: relative;
  }
}

.largeScreenNav {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}

.smallScreenNav {
  @include breakpoint($md) {
    display: none;
  }
}
