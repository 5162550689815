@import 'config.scss';

.banner {
  width: 100%;
  perspective: 1000;
  transform: translateY(-100%);
  height: $banner-mobile-height;

  @include breakpoint($md) {
    height: $banner-desktop-height;
  }

  .bannerRichText {
    padding: 10px 40px 10px 25px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: $white;
    background-color: $purple-600;
    position: relative;
    text-overflow: ellipsis;

    span {
      width: 25px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      color: $white;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    a {
      color: $golden-yellow;

      &:hover {
        color: lighten($white, 30%);
      }
    }

    @include breakpoint($md) {
      padding: 15px;
      padding-right: 50px;
      font-size: 15px;
      line-height: auto;

      span {
        right: 20px;
      }
    }
  }
}

.open {
  transform: translateY(0);
}
