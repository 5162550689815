@import 'config.scss';

.Page {
  width: 100%;
  min-height: 120vh;
  position: relative;

  &.cloudPage {
    background: linear-gradient(
      to bottom,
      rgba(72, 69, 255, 0.1) 0%,
      rgb(244, 244, 249) 15%
    );
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      width: 50%;
      height: 90px;

      @include breakpoint($md) {
        height: 304px;
      }
    }

    &:after {
      right: 0;
      top: 550px;
      background-image: url('/assets/right-side-cloud.png');
      background-position: right;
    }
    &:before {
      left: 0;
      top: 150px;
      background-image: url('/assets/left-side-cloud.png');
    }

    @include breakpoint($md) {
      padding-top: 86px;

      &:not(.darkMode) {
        &:after,
        &:before {
          max-width: 240px;
        }
      }
    }
  }

  &.darkMode {
    background: linear-gradient(
      180deg,
      $black-dark-mode 0%,
      $blue-dark-mode 610px
    );
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      width: 50%;
      height: 90px;

      @include breakpoint($md) {
        height: 144px;
      }

      opacity: 0.3;
    }

    &:after {
      right: 0;
      background-image: url('/assets/dark-mode-right-corner-art.svg');
      background-position: right;
    }
    &:before {
      left: 0;
      background-image: url('/assets/dark-mode-left-corner-art.svg');
    }

    @include breakpoint($md) {
      padding-top: 86px;

      &:not(.darkMode) {
        &:after,
        &:before {
          max-width: 240px;
        }
      }
    }
  }

  .main {
    // This margin fixes gap between header
    margin-top: -81px;
  }

  .bannerSticky {
    padding-top: 0;
  }
}
