@import 'config.scss';
@import 'marketplace/styles/Header/index.scss';

.Desktop {
  z-index: 9998;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  transform: translateZ(1000px);

  .button {
    display: none;
    white-space: nowrap;
    transition: background-color 0.1s linear, color 0.1s linear;
    margin-right: 10px;
    /* Add this line to add space between buttons */

    .buttonLink {
      padding: 14px 24px 15px 24px;

      @include breakpoint($md) {
        display: block;
        padding: 10px 15px 11px 15px;
      }

      @include breakpoint($lg) {
        padding: 14px 22px 15px 22px;
      }
    }

    @include breakpoint($md) {
      display: block;
    }
  }

  .buttonSmall {
    padding: 8px 12px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Remove the margin on the last button to avoid extra space */
  .button:last-child {
    margin-right: 0;
  }

  .contactSalesButtonDark {
    display: none;
    text-decoration: none;
    color: #32324d;
    white-space: nowrap;
    transition: background-color 0.1s linear, color 0.1s linear;

    .contactSalesButtonLink {
      padding: 14px 24px 15px 24px;
    }

    @include breakpoint($lg) {
      display: block;
      margin: 0 24px 0 0;
    }
  }

  .contactSalesButtonLight {
    display: none;
    text-decoration: none;
    color: white;
    white-space: nowrap;
    transition: background-color 0.1s linear, color 0.1s linear;

    .contactSalesButtonLink {
      padding: 14px 24px 15px 24px;
    }

    @include breakpoint($lg) {
      display: block;
      margin: 0 24px 0 0;
    }
  }

  .githubBtn {
    display: none;

    @include breakpoint(1400) {
      display: flex;
      margin: 0 9px 0 0;
    }
  }
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0 0 0;
  transform: translateZ(1000px);
}

.navLaunchWeek {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 17.5px 32px 17.5px 32px;
  transform: translateZ(1000px);
}

.logo {
  display: block;
  min-width: 100px;

  @include breakpoint($lg) {
    min-width: 132px;
  }
}

.links {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  flex: 1;

  @include breakpoint($lg) {
    margin-left: 5px;
  }
}

.launchWeekLinks {
  margin-left: 40px;
}

.link {
  white-space: nowrap;
}

.panelsWrapper {
  position: absolute;
  top: calc(100% + 35px);
  left: 0;
  width: 100%;
}

.panelBackgroundWrapper {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0px;
  border-radius: 10px;
  transform: translateZ(-100px);
  visibility: hidden;
}

.panelBackgroundContainer {
  position: absolute;
  width: calc(100% + 32px);
  top: 0px;
  left: -16px;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.panelBackground {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px -8px 15px rgba(28, 27, 126, 0.1);
}

.panelBackgroundGradient {
  left: 0;
  border-right: solid 1px $mystic-gray;
  background: linear-gradient(
    269deg,
    #f9faff -5.1%,
    rgba(255, 255, 255, 0) 55.32%
  );
}

.contactSalesLink {
  color: #c0c0cf;
  padding-right: 20px;
  padding-left: 20px;
  text-decoration: none;
}

.launchWeek {
  color: #c0c0cf;
  width: 15px;
  height: 15px;
  margin: auto;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 1px;
  cursor: pointer;

  path {
    fill: #c0c0cf;
  }
}
.iconDark {
  width: 15px;
  height: 15px;
  margin: auto;
  margin-right: 10px;
  margin-top: 1px;
  cursor: pointer;

  @include breakpoint($lg) {
    margin-right: 15px;
  }

  path {
    fill: #ffffff;
    /* changes the color to red */
  }
}

.iconLight {
  width: 15px;
  height: 15px;
  margin: auto;
  margin-right: 15px;
  margin-top: 1px;
  cursor: pointer;
}
